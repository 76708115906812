import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { db } from 'config/firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import TourCard from '../../components/TourCard';
import { Grid, Container, Box, Typography, CircularProgress, Alert, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom'; // Corrected import

function Transfers() {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const locationIds = ["324053", "323977"];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchToursByIds = async () => {
      const fetchedTours = [];
      for (const id of locationIds) {
        const docRef = doc(db, 'activityDetails', id);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            let tourData = docSnap.data();
            // Fetch additional details from rewrittenTourDescriptions
            const rewrittenRef = doc(db, 'rewrittenTourDescriptions', id);
            const rewrittenSnap = await getDoc(rewrittenRef);
            if (rewrittenSnap.exists()) {
              const rewrittenData = rewrittenSnap.data();
              // Override or add the necessary fields from rewrittenTourDescriptions
              tourData.snippet = rewrittenData.snippet; // Ensure this matches the field name in Firestore
              tourData.duration = rewrittenData.duration;
              tourData.days = rewrittenData.days;
            }
            fetchedTours.push({ id: docSnap.id, ...tourData });
          } else {
            console.error("No document found for ID:", id);
          }
        } catch (error) {
          console.error("Error fetching tour details:", error);
          setError('Failed to fetch tours. Please try again later.');
        }
      }
      setTours(fetchedTours);
      setLoading(false);
    };

    fetchToursByIds();
  }, []);

  const headerImageStyle = {
    width: '100%',
    height: '100vh', // Full viewport height
    objectFit: 'cover', // Keep the aspect ratio
    position: 'absolute', // Position it absolutely to cover the whole section
    top: 0,
    left: 0,
    zIndex: -1 // Ensure it stays in the background
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Helmet>
        <title>Transfers - Elevate Your Travel Experience with Curacao Tours</title>
        <meta name="description" content="Discover our exclusive transfer services in Curacao, offering a standard of excellence for your travel. Learn more and book your spot today." />
        <meta name="keywords" content="Curacao transfers, travel excellence, exclusive transfer services, Curacao travel, tour services" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Travel Services",
            "provider": {
              "@type": "Organization",
              "name": "Curacao Tours",
              "url": "http://curacao-tours.com"
            },
            "description": "Exclusive transfer services in Curacao that elevate your travel experience to the ultimate standard of excellence.",
            "areaServed": {
              "@type": "Place",
              "name": "Curacao"
            }
          })}
        </script>
      </Helmet>

      {/* Full Width Hero Image */}
      <Box sx={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
        <img
          src="/images/Curacao_Tours_Transfers.jpg"
          alt="Transfers Header"
          style={headerImageStyle}
        />
      </Box>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <TransfersContent tours={tours} matches={matches} />
      )}
    </Container>
  );
}

function TransfersContent({ tours, matches }) {
  return (
    <>
      <Typography variant="h4" component="h2" sx={{ my: 0, textAlign: 'center', fontWeight: 'bold' }}>
        Elevate Your Travel Experience
        <br />
        Indulge in the Ultimate Standard of Excellence with Our Exclusive Transfer.
      </Typography>
      <Box sx={{ my: 6 }}>
        <Grid container spacing={matches ? 2 : 4}>
          {tours.map((tour) => (
            <Grid item key={tour.id} xs={12} sm={6} md={4}>
              <Link to={`/tour/${tour.id}`} style={{ textDecoration: 'none' }}>
                <TourCard tour={tour} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default Transfers;
