import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { db } from 'config/firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import TourCard from '../../components/TourCard';
import { Grid, Container, Box, Typography, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom'; // Corrected import for routing

function LocalDeals() {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [headerImage, setHeaderImage] = useState('/images/Curacao_Tours_Local_Tours.jpg'); // Set default header image
  const locationIds = ["321833", "327479", "296972"];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchToursByIds = async (ids) => {
      const tours = [];
      for (const id of ids) {
        const docRef = doc(db, 'activityDetails', id);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            let tourData = docSnap.data();
            // Fetch additional details from rewrittenTourDescriptions
            const rewrittenRef = doc(db, 'rewrittenTourDescriptions', id);
            const rewrittenSnap = await getDoc(rewrittenRef);
            if (rewrittenSnap.exists()) {
              const rewrittenData = rewrittenSnap.data();
              // Override or add the necessary fields from rewrittenTourDescriptions
              tourData.snippet = rewrittenData.snippet; // Ensure this matches the field name in Firestore
              tourData.duration = rewrittenData.duration;
              tourData.days = rewrittenData.days;
            }
            tours.push({ id: docSnap.id, ...tourData });
          } else {
            console.error("No document found for ID:", id);
          }
        } catch (error) {
          console.error("Error fetching tour details:", error);
          setError('Failed to fetch tour details. Please try again later.');
        }
      }
      setTours(tours);
      setLoading(false);
    };

    // Comment out or remove this function call to prevent dynamic updates to the header image
    // fetchHeaderAndGalleryImages();
    fetchToursByIds(locationIds);
  }, []);

  const headerImageStyle = {
    width: '100%',
    height: '100vh', // Full viewport height
    objectFit: 'cover', // Keep the aspect ratio
    position: 'absolute', // Position it absolutely to cover the whole section
    top: 0,
    left: 0,
    zIndex: -1 // Ensure it stays in the background
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 0, mb: 0 }}>
      <Helmet>
        <title>Exclusive Local Deals for Curacao Residents - Curacao Tours</title>
        <meta name="description" content="Unlock exclusive local deals and special offers for Curacao residents. Dive into unforgettable experiences with our tailored tours. Don't miss out, book now!" />
      </Helmet>

      {/* Full Width Hero Image */}
      <Box sx={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
        <img src={headerImage} alt="Local Deals Header" style={headerImageStyle} />
      </Box>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography variant="h6" sx={{ textAlign: 'center', my: 2 }}>{error}</Typography>
      ) : (
        <>
          <Typography variant="h4" component="h2" sx={{ my: 4, textAlign: 'center', fontWeight: 'bold' }}>
            DO YOU HAVE A VALID SEDULA?<br />BOOK OUR LOCAL SPECIALS NOW!
          </Typography>
          <Box sx={{ my: 6 }}>
            <Grid container spacing={matches ? 2 : 4}>
              {tours.map((tour) => (
                <Grid item key={tour.id} xs={12} sm={6} md={4}>
                  <Link to={`/tour/${tour.id}`} style={{ textDecoration: 'none' }}>
                    <TourCard tour={tour} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </Container>
  );
}

export default LocalDeals;
