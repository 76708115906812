import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Grid, Typography, TextField, Button, Box, Paper, useTheme, useMediaQuery
} from '@mui/material';
import Link from '@mui/material/Link';

function ContactPage() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const googleMapsUrl = "https://www.google.com/maps/place/Seru+di+Mahuma+18-20,+Willemstad,+Curaçao/";

  const heroImageStyle = {
    width: '100%', // Ensure the image covers the width
    height: '100vh', // Full viewport height
    objectFit: 'cover', // Keep the aspect ratio
    position: 'absolute', // Position it absolutely to cover the whole section
    top: 0,
    left: 0,
    zIndex: -1 // Ensure it stays in the background
  };

  // Assuming cardStyle is defined as needed elsewhere in your component or application
  const cardStyle = {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background for better readability
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
      <Helmet>
        <title>Contact Us - FBTT Travel Curacao</title>
        <meta name="description" content="Reach out to FBTT Travel Curacao for the best travel and tour services on the island. Contact us today via phone, WhatsApp, or email." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelAgency",
            "name": "FBTT Travel Curacao",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Seru di Mahuma #18 - 20",
              "addressLocality": "Willemstad",
              "addressRegion": "Curaçao",
              "postalCode": "",
              "addressCountry": "CW"
            },
            "url": "http://curacao-tours.com",
            "telephone": "+5999 8699559",
            "email": "info@fb-tt.com",
            "sameAs": []
          })}
        </script>
      </Helmet>

      {/* Full Width Hero Image */}
      <Box sx={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
        <img
          src="/images/Curacao_Tours_Contact.jpg"
          alt="FBTT Travel Curacao"
          style={heroImageStyle}
        />
      </Box>

      {/* Rest of the page content */}
      <Grid container spacing={matches ? 2 : 4} alignItems="stretch" style={{ position: 'relative' }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={cardStyle}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              Contact Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              Seru di Mahuma #18 - 20, Willemstad, Curaçao
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Link href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                View on Google Maps
              </Link>
            </Typography>
            <Typography variant="body1" gutterBottom>Phone: +5999 8699559</Typography>
            <Typography variant="body1" gutterBottom>WhatsApp: +5999 5146060</Typography>
            <Typography variant="body1">Email: info@fb-tt.com</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={cardStyle}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>
              Send Us an Email
            </Typography>
            <form noValidate autoComplete="off">
              <TextField fullWidth label="Name *" variant="filled" margin="normal" required />
              <TextField fullWidth label="E-mail Address *" variant="filled" margin="normal" required />
              <TextField fullWidth label="Phone Number" variant="filled" margin="normal" />
              <TextField fullWidth label="Subject" variant="filled" margin="normal" />
              <TextField fullWidth label="Message *" variant="filled" margin="normal" required multiline rows={4} />
              <Button variant="contained" color="primary" sx={{ mt: 2, fontWeight: 'bold' }}>
                Send Email
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ContactPage;
