import React from 'react';
import { Card, CardMedia, Typography, Button, Box, Divider, Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

function TourCard({ tour }) {
  const navigate = useNavigate();

  if (!tour) {
    return null;
  }

  const { title, snippet, days = '', duration, fromPrice, images, id } = tour;
  const imageUrl = images?.header?.M || '';
  const price = fromPrice ? `${fromPrice.amount.toFixed(2)} ${fromPrice.currency}` : 'Price on request';
  const moreInfoUrl = `/tour/${id}`;

  const handleNavigation = (e) => {
    e.preventDefault();
    navigate(moreInfoUrl);
  };

  return (
    <Card
      component={Link}
      to={moreInfoUrl}
      sx={{
        maxWidth: 345,
        boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
        borderRadius: '4px',
        overflow: 'hidden',
        transition: 'transform 0.3s ease-in-out',
        ':hover': {
          transform: 'scale(1.03)',
        },
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <CardMedia
        component="img"
        height="194"
        image={imageUrl}
        alt={title || 'Tour image'}
      />
      <Box sx={{ p: 2, backgroundColor: '#E6E9F3' }}>
        <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          {title || 'No title'}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          {snippet}
        </Typography>
        <Divider sx={{ my: 2, borderBottomWidth: 2, borderColor: 'black', opacity: 0.3 }} />
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <Typography variant="body2" color="text.secondary">
              <strong>Duration: {duration}</strong>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Days: {days.replace("Days: ", "")}</strong>
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleNavigation}
            >
              LEARN MORE
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#D9DDED' }}>
        <Typography variant="h6" color="text.primary" sx={{ fontWeight: 'bold' }}>
          {price}
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleNavigation}
          sx={{
            backgroundColor: '#007bff',
            ':hover': {
              backgroundColor: '#0056b3',
            }
          }}
        >
          BOOK NOW
        </Button>
      </Box>
    </Card>
  );
}

export default TourCard;