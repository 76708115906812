import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, zoomPlugin);

const MonthlyTourRevenue = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });
    const [selectedTour, setSelectedTour] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [tourOptions, setTourOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [tourRevenueMap, setTourRevenueMap] = useState({});

    useEffect(() => {
        const years = [2021, 2022, 2023, 2024];
        let localTourRevenueMap = {}; // Temporarily hold the data structure

        const fetchData = async () => {
            for (const year of years) {
                const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_${year}!A2:G`;
                try {
                    const response = await fetch(functionUrl);
                    if (!response.ok) throw new Error('Failed to fetch');
                    const data = await response.json();

                    data.forEach(row => {
                        const date = new Date(row[0]);
                        const month = date.getMonth();
                        const tourTitle = row[3];
                        const revenue = parseFloat(row[4]) || 0;

                        if (!localTourRevenueMap[tourTitle]) {
                            localTourRevenueMap[tourTitle] = {};
                        }
                        if (!localTourRevenueMap[tourTitle][year]) {
                            localTourRevenueMap[tourTitle][year] = new Array(12).fill(0);
                        }

                        localTourRevenueMap[tourTitle][year][month] += revenue;
                    });
                } catch (error) {
                    console.error(`Error fetching data for ${year}:`, error);
                }
            }

            setTourRevenueMap(localTourRevenueMap);
            setTourOptions(Object.keys(localTourRevenueMap));
            setYearOptions(years);
            setSelectedTour(Object.keys(localTourRevenueMap)[0]); // Default to the first tour
            setSelectedYear(years[0]); // Default to the first year
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!selectedTour || !selectedYear) return;

        let datasets = [];
        if (selectedTour === 'All Tours') {
            datasets = Object.entries(tourRevenueMap).map(([tour, years]) => ({
                label: `${tour} - Year ${selectedYear}`,
                data: years[selectedYear] || new Array(12).fill(0),
                borderColor: getRandomColor(),
                backgroundColor: getRandomColor(),
                fill: false,
            }));
        } else {
            datasets = [{
                label: `Year ${selectedYear}`,
                data: tourRevenueMap[selectedTour][selectedYear] || new Array(12).fill(0),
                borderColor: getRandomColor(),
                backgroundColor: getRandomColor(),
                fill: false,
            }];
        }

        setChartData({
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets,
        });
    }, [selectedTour, selectedYear, tourRevenueMap]);

    const handleTourChange = (event) => {
        setSelectedTour(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    // Utility function to generate random colors for chart bars
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    return (
        <div>
            <h2>Monthly Tour Revenue</h2>
            <FormControl fullWidth margin="normal">
                <InputLabel id="tour-select-label">Select a Tour</InputLabel>
                <Select
                  labelId="tour-select-label"
                  id="tour-select"
                  value={selectedTour}
                  label="Select a Tour"
                  onChange={handleTourChange}
                >
                  <MenuItem value="All Tours">All Tours</MenuItem>
                  {tourOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel id="year-select-label">Select a Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  id="year-select"
                  value={selectedYear}
                  label="Select a Year"
                  onChange={handleYearChange}
                >
                  {yearOptions.map((year) => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                  ))}
                </Select>
            </FormControl>
            <Bar 
                data={chartData} 
                options={{ 
                    responsive: true, 
                    plugins: { 
                        legend: { position: 'top' },
                        zoom: {
                            pan: {
                                enabled: true,
                                mode: 'xy',
                            },
                            zoom: {
                                wheel: {
                                    enabled: true,
                                },
                                pinch: {
                                    enabled: true,
                                },
                                drag: {
                                    enabled: true,
                                    borderColor: 'rgba(225,225,225,0.3)',
                                    borderWidth: 5,
                                    backgroundColor: 'rgb(225,225,225)',
                                    animationDuration: 0
                                },
                                mode: 'xy',
                            }
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            min: 0,
                            ticks: {
                                callback: function(value) {
                                    return value < 0 ? 0 : value;
                                }
                            }
                        }
                    }
                }} 
            />
        </div>
    );
};

export default MonthlyTourRevenue;
