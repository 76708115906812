// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBhQF2vocdUXetFA68uHQmCQJ69trZ4_IM",
  authDomain: "curacao-tours.firebaseapp.com",
  projectId: "curacao-tours",
  storageBucket: "curacao-tours.appspot.com",
  messagingSenderId: "65353935429",
  appId: "1:65353935429:web:8a9a3791bbd02cd202112c",
  measurementId: "G-Y2Z69MMKPW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Functions
const functions = getFunctions(app);

export { app, db, functions, analytics };
