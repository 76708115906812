import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Button, Card, CardContent, Grid, Box, ListItemIcon, useMediaQuery, useTheme, Divider } from '@mui/material';
import { db } from 'config/firebase/firebase';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrekksoftBookingWidget from 'hooks/useTrekksoftLoader';
import FooterMobile from 'components/FooterMobile.js';
import RecommendedTours from './RecommendedTours.js';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

function TemplateTourPage() {
  const { tourId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [tourDetails, setTourDetails] = useState({
    headerImage: '',
    shortTitle: '',
    description: '',
    duration: '',
    price: '',
    highlights: [],
    travelerTips: [],
    requirements: [],
    included: [], // Initialize as an empty array
    days: ''
  });

  const [galleryImages, setGalleryImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [recommendedTours, setRecommendedTours] = useState([]);

  const headerImageStyle = {
    width: '100%',
    height: isMobile ? '50vh' : '100vh', // Reduce height on mobile
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    padding: 0,
    margin: 0
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: isMobile ? 0 : '-250px', // Adjust overlay position for mobile
    width: '100%',
    height: isMobile ? '50vh' : '100vh', // Match header image height
    zIndex: 0,
    backgroundImage: 'url(/images/Overlay.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    opacity: 0.5 // Adjust opacity to 50%
  };

  useEffect(() => {
    const fetchTourDetailsAndImages = async () => {
      try {
        const tourDetailsDocRef = doc(db, 'rewrittenTourDescriptions', tourId);
        const tourDetailsSnapshot = await getDoc(tourDetailsDocRef);

        if (tourDetailsSnapshot.exists()) {
          const data = tourDetailsSnapshot.data();
          let includedData = data.included || [];
          
          // If included is a string, split it into an array
          if (typeof includedData === 'string') {
            includedData = includedData.split(',').map(item => item.trim());
          }

          setTourDetails({
            shortTitle: data.shortTitle,
            description: data.description,
            duration: data.duration,
            price: data.price,
            highlights: data.highlights || [],
            travelerTips: data.travelerTips || [],
            requirements: data.requirements || [],
            included: includedData,
            days: data.days || ''
          });
        } else {
          console.log("No tour details found!");
        }

        const activityDetailsDocRef = doc(db, 'activityDetails', tourId);
        const activityDetailsSnapshot = await getDoc(activityDetailsDocRef);

        if (activityDetailsSnapshot.exists()) {
          const activityData = activityDetailsSnapshot.data();
          let headerImage = activityData.images?.header?.ORIGINAL || activityData.images?.header?.XL || activityData.images?.header?.L || '';
          setTourDetails(prevDetails => ({ ...prevDetails, headerImage }));

          let gallery = activityData.images?.gallery?.map(img => img.XL || img.L || img.ORIGINAL) || [];
          setGalleryImages(gallery);
        } else {
          console.log("No activity details found!");
        }

        const recommendedToursSnapshot = await getDocs(collection(db, 'recommendedTours'));
        const recommendedToursData = recommendedToursSnapshot.docs.map(doc => doc.data());
        setRecommendedTours(recommendedToursData);

      } catch (error) {
        console.error("Error fetching tour details and images:", error);
      }
    };

    fetchTourDetailsAndImages();
  }, [tourId]);

  const handleBookNowClick = () => {
    const bookingUrl = `https://bw.trekksoft.com/views/window.html?type=activity-booking&id=${tourId}&clientId=BOOKIE-5badffc8663be9c7621fe18cf&token=f644f78038045737f3c2da77ea17bf4521b12a1ccdc0aded`;

    const windowFeatures = "width=600,height=800,scrollbars=yes,resizable=yes";
    window.open(bookingUrl, 'BookingWindow', windowFeatures);
  };

  const handleImageClick = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div>
      <Box sx={{ width: '100%', height: isMobile ? '50vh' : '100vh', overflow: 'hidden', p: 0, m: 0, position: 'relative' }}>
        <img src={tourDetails.headerImage} alt="Main feature" style={headerImageStyle} />
        <div style={overlayStyle} />
      </Box>

      <Grid container spacing={2} sx={{ 
        marginTop: 2, 
        paddingLeft: { xs: 2, md: 10 }, 
        paddingRight: { xs: 2, md: 10 },
        flexDirection: { xs: 'column-reverse', md: 'row' } // Reverse order on mobile
      }}>
        <Grid item xs={12} md={7}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, mb: 2, backgroundColor: '#f3f4f6' }}>
            <CardContent sx={{ paddingLeft: 2, paddingRight: 2 }}>
              <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', mb: 4, fontSize: { xs: '1.5rem', md: '2rem' } }}>
                {tourDetails.shortTitle}
              </Typography>

              <Typography variant="body1" paragraph sx={{ mb: 4 }}>
                {tourDetails.description}
              </Typography>

              <Divider sx={{ my: 2 }} />

              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                </Grid>
                <Grid item>
                  <Typography variant="body2">Duration: {tourDetails.duration}</Typography>
                </Grid>
                <Grid item>
                  <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                </Grid>
                <Grid item>
                  <Typography variant="body2">USD {tourDetails.price}</Typography>
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              <Typography variant="subtitle1" sx={{ mt: 3, mb: 2, fontWeight: 'bold' }}>
                Days:
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>{tourDetails.days}</Typography>

              <Divider sx={{ my: 2 }} />

              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>Included:</Typography>
              {Array.isArray(tourDetails.included) && tourDetails.included.length > 0 ? (
                tourDetails.included.map((include, index) => (
                  <Typography key={index} variant="body2" sx={{ mb: 1 }}>• {include}</Typography>
                ))
              ) : (
                <Typography variant="body2" sx={{ mb: 1 }}>No information available</Typography>
              )}

              <Divider sx={{ my: 2 }} />

              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>Requirements:</Typography>
              {Array.isArray(tourDetails.requirements) && tourDetails.requirements.map((requirement, index) => (
                <Typography key={index} variant="body2" sx={{ mb: 1 }}>• {requirement}</Typography>
              ))}

              <Divider sx={{ my: 2 }} />

              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>Traveler Tips:</Typography>
              {Array.isArray(tourDetails.travelerTips) && tourDetails.travelerTips.map((tip, index) => (
                <Typography key={index} variant="body2" sx={{ mb: 1 }}>• {tip}</Typography>
              ))}

              <Divider sx={{ my: 2 }} />

              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>Highlights:</Typography>
              {Array.isArray(tourDetails.highlights) && tourDetails.highlights.map((highlight, index) => (
                <Typography key={index} variant="body2" sx={{ mb: 1 }}>• {highlight}</Typography>
              ))}

              <Divider sx={{ my: 2 }} />

              <Box sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on mobile
                justifyContent: 'space-between', 
                alignItems: { xs: 'stretch', md: 'center' }, 
                mt: 4 
              }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: { xs: 2, md: 0 } }}>
                  USD {tourDetails.price}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleBookNowClick}
                  fullWidth={isMobile} // Full width button on mobile
                >
                  Book Now
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={5}>
          <Card sx={{
            boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
            borderRadius: '0px',
            overflow: 'hidden',
            minHeight: { xs: '300px', md: '400px' }, // Adjust min-height for mobile
            backgroundColor: '#f3f4f6'
          }}>
            <CardContent>
              <Grid container spacing={1}>
                {galleryImages.slice(1, isMobile ? 3 : 5).map((image, index) => ( // Show fewer images on mobile
                  <Grid item xs={6} key={index}>
                    <Box
                      sx={{
                        width: '100%',
                        height: { xs: '150px', md: '255px' }, // Reduce image height on mobile
                        overflow: 'hidden',
                        '& img': {
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '20px',
                        },
                      }}
                      onClick={() => handleImageClick(index)}
                    >
                      <img src={image} alt={`Gallery image ${index + 1}`} />
                    </Box>
                  </Grid>
                ))}
              </Grid>

              <Box
                id="trekksoft-widget-container"
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  overflowX: 'auto', // Enable horizontal scrolling
                  justifyContent: 'left',
                  alignItems: 'center',
                  mt: 2
                }}
              >
                <TrekksoftBookingWidget tourId={tourId} />
                
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {isOpen && (
        <Lightbox
          mainSrc={galleryImages[photoIndex]}
          nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
          prevSrc={galleryImages[(photoIndex + galleryImages.length - 1) % galleryImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + galleryImages.length - 1) % galleryImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % galleryImages.length)}
        />
      )}

      <Box
        sx={{
          my: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#ffffff',
          p: 2,
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Typography variant="h4" gutterBottom component="div">
          Recommended Tours
        </Typography>
        <RecommendedTours />
      </Box>

      {isMobile && (
        <FooterMobile
          onBookNowClick={handleBookNowClick}
          title={tourDetails.shortTitle}
        />
      )}
    </div>
  );
}

export default TemplateTourPage;