import React, { useState, useEffect } from 'react';
import { db } from 'config/firebase/firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import TourCard from '../../components/TourCard';
import { Grid, Container, Box, Typography, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';

function HomePageTours() {
  const [selectedTours, setSelectedTours] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [error, setError] = useState('');
  const [headerImage, setHeaderImage] = useState('');
  const [showHeaderImage, setShowHeaderImage] = useState(false); // Set to false to hide the header image
  const locationIds = ["240610", "240613", "293823", "296605", "296606", "300656", "301652", "304151", "311035", "318608", "318803", ];

  useEffect(() => {
    let timeoutId;
    setLoading(true);

    const fetchHeaderImage = async () => {
      try {
        const randomIndex = Math.floor(Math.random() * locationIds.length);
        const selectedId = locationIds[randomIndex];
        const docRef = doc(db, 'activityDetails', selectedId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setHeaderImage(data.images.header.XL); // Set the header image URL
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching header image:", error);
      }
    };

    const fetchTourDetails = async (tourIds) => {
      const promises = tourIds.map(async (id) => {
        const docRef = doc(db, 'activityDetails', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          let tourData = docSnap.data();
          const rewrittenRef = doc(db, 'rewrittenTourDescriptions', id);
          const rewrittenSnap = await getDoc(rewrittenRef);
          if (rewrittenSnap.exists()) {
            const rewrittenData = rewrittenSnap.data();
            tourData.snippet = rewrittenData.snippet;
            tourData.days = rewrittenData.days;
            tourData.duration = rewrittenData.duration;
          }
          return { id: docSnap.id, ...tourData };
        }
      });
      return Promise.all(promises);
    };

    const fetchHomePageTours = async () => {
      try {
        const q = query(collection(db, 'activitySelections'), where('homePage', '==', true));
        const querySnapshot = await getDocs(q);
        let tourIds = [];
        querySnapshot.forEach((doc) => {
          tourIds.push(doc.id);
        });

        const tours = await fetchTourDetails(tourIds);
        setSelectedTours(tours);
      } catch (error) {
        console.error('Error fetching selected tours:', error);
        setError('Failed to fetch tours. Please try again later.');
      }
    };

    timeoutId = setTimeout(() => {
      setShowLoadingIndicator(true);
    }, 2000); // Only show loading indicator if loading takes more than 2 seconds

    Promise.all([fetchHeaderImage(), fetchHomePageTours()]).then(() => {
      setLoading(false);
      clearTimeout(timeoutId);
    });
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} id="tours-section">
      {loading && showLoadingIndicator ? (
        <CircularProgress />
      ) : error ? (
        <Typography variant="h6">{error}</Typography>
      ) : (
        <>
          {/* Display only the header image if showHeaderImage is true */}
          {showHeaderImage && (
            <Box sx={{ my: 4, borderRadius: '20px', overflow: 'hidden' }}>
              {headerImage && (
                <img src={headerImage} alt="Header" style={{ 
                  width: '100%', 
                  maxHeight: '500px', 
                  objectFit: 'cover', 
                  borderRadius: '20px'
                }} />
              )}
            </Box>
          )}
          <Grid container spacing={4}>
            {selectedTours.map((tour) => (
              <Grid item key={tour.id} xs={12} sm={6} md={4} lg={4}>
                <Link to={`/tour/${tour.id}`} style={{ textDecoration: 'none' }}>
                  <TourCard tour={tour} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Container>
  );
}

export default HomePageTours;
