import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './dashboard.css'; // Ensure this path is correct

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const NumberOfPassengersSardonyxOTAlocation = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    const fetchData = async () => {
        const years = [2023, 2024]; // Fetch data for both 2023 and 2024
        let datasets = [];
        let monthlyPassengerMapByLocation = {};

        for (const year of years) {
            const sheetName = year === 2024 ? 'Sardonyx' : `Sardonyx${year}`; // Determine the correct sheet name based on the year
            const range = `${sheetName}!A2:I`;
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                data.forEach(row => {
                    if (!row || !row[0] || !row[8]) return; // Check if row, date, or OTA location is undefined
                    const dateStr = row[0];
                    const otaLocation = row[8];
                    let date;
                    if (year === 2024) { // DD/MM/YYYY format
                        const [day, month, year] = dateStr.split('/').map(Number);
                        date = new Date(year, month - 1, day);
                    } else { // MM/DD/YYYY format
                        const [month, day, year] = dateStr.split('/').map(Number);
                        date = new Date(year, month - 1, day);
                    }

                    const month = date.getMonth();
                    const monthKey = `${year}-${month + 1}`; // Create a key for the month
                    const passengers = parseInt(row[2], 10);

                    if (!monthlyPassengerMapByLocation[otaLocation]) {
                        monthlyPassengerMapByLocation[otaLocation] = {};
                    }
                    if (!monthlyPassengerMapByLocation[otaLocation][monthKey]) {
                        monthlyPassengerMapByLocation[otaLocation][monthKey] = 0;
                    }
                    monthlyPassengerMapByLocation[otaLocation][monthKey] += passengers;
                });
            } catch (error) {
                console.error(`Error fetching data for year ${year}:`, error);
            }
        }

        // Convert the map into datasets for the chart
        Object.entries(monthlyPassengerMapByLocation).forEach(([location, data], index) => {
            const color = `hsla(${360 * index / Object.keys(monthlyPassengerMapByLocation).length}, 70%, 70%, 0.6)`;
            datasets.push({
                label: location,
                data: Object.values(data),
                backgroundColor: color,
                borderColor: color.replace('0.6', '1'),
                borderWidth: 1,
                yAxisID: 'y'
            });
        });

        setChartData({
            labels: Object.keys(monthlyPassengerMapByLocation[Object.keys(monthlyPassengerMapByLocation)[0]]), // Assuming all locations have data for all months
            datasets
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const refreshData = () => {
        fetchData();
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.parsed.y}`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#333',
                },
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                    color: '#eaeaea',
                },
                ticks: {
                    color: '#333',
                    beginAtZero: true,
                },
            },
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
        },
    };

    return (
        <div className="chart-container">
            <h2 className="chart-title">Number of Passengers by OTA Location (Sardonyx)</h2>
            <button onClick={refreshData}>Refresh Data</button>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default NumberOfPassengersSardonyxOTAlocation;