import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, CircularProgress, Link } from '@mui/material';

const CurrentYearRevenue = () => {
    const [revenue, setRevenue] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    // State for revenue from 2022 and 2023
    const [revenue2022, setRevenue2022] = useState(0);
    const [revenue2023, setRevenue2023] = useState(0);

    const fetchYearRevenue = async (year) => {
        const range = `Bookings_${year}!A2:G`;
        const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

        try {
            const response = await fetch(functionUrl);
            if (!response.ok) throw new Error('Failed to fetch');
            const data = await response.json();

            let totalRevenue = data.reduce((sum, row) => {
                const revenue = parseFloat(row[4]); // Assuming the revenue is in the 5th column
                return sum + (isNaN(revenue) ? 0 : revenue);
            }, 0);

            return totalRevenue;
        } catch (error) {
            console.error(`Error fetching revenue for ${year}:`, error);
            return 0;
        }
    };

    useEffect(() => {
        setIsLoading(true);
        const currentYear = new Date().getFullYear();

        const fetchAllRevenue = async () => {
            const revenueCurrent = await fetchYearRevenue(currentYear);
            const revenueFor2022 = await fetchYearRevenue(2022);
            const revenueFor2023 = await fetchYearRevenue(2023);

            setRevenue(revenueCurrent);
            setRevenue2022(revenueFor2022);
            setRevenue2023(revenueFor2023);
            setIsLoading(false);
        };

        fetchAllRevenue();
    }, []);

    return (
        <Card sx={{ minWidth: 275, minHeight: 140, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <CardContent sx={{ width: '100%' }}>
                {isLoading ? (
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Typography variant="h5" component="div" gutterBottom>
                            <Box fontWeight="fontWeightBold" m={1}>
                                Total Revenue for {new Date().getFullYear()}:
                            </Box>
                            <Box fontWeight="fontWeightRegular" m={1} fontSize="h4.fontSize" color="primary.main">
                                ${revenue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Box>
                        </Typography>
                        <Box display="flex" justifyContent="space-around" width="100%" mt={2}>
                            <Box textAlign="center">
                                <Typography variant="subtitle1" component="div">2022 Revenue</Typography>
                                <Typography variant="body1" color="text.secondary">${revenue2022.toLocaleString()}</Typography>
                            </Box>
                            <Box textAlign="center">
                                <Typography variant="subtitle1" component="div">2023 Revenue</Typography>
                                <Typography variant="body1" color="text.secondary">${revenue2023.toLocaleString()}</Typography>
                            </Box>
                        </Box>

                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default CurrentYearRevenue;
