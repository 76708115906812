// src/components/MobileMenu.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Drawer, Box, List, ListItem, ListItemText, useTheme, AppBar, Toolbar, Collapse, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import WeatherWidget from './WeatherWidget';
import { db } from 'config/firebase/firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';

function MobileMenu() {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [openCategories, setOpenCategories] = useState({});
  const [menuCategories, setMenuCategories] = useState({});

  useEffect(() => {
    const fetchMenuItems = async () => {
      const q = query(collection(db, 'activitySelections'), where('homePage', '==', true));
      const querySnapshot = await getDocs(q);
      const items = await Promise.all(querySnapshot.docs.map(async docSnapshot => {
        const activityDoc = await getDoc(doc(db, 'activityDetails', docSnapshot.id));
        return {
          title: activityDoc.exists() ? activityDoc.data().title : 'No Title',
          link: `/tour/${docSnapshot.id}`
        };
      }));

      const categories = {
        'Explore': ['Beach Escape', 'Curaçao Island Tour', 'Historic Walking Tour', 'Trolley Train City Centre'],
        'Water Tours': ['SeaWorld Explorer', 'Half Day Snorkel Tour by Boat', 'Sunset Cruise'],
        'Experiential': ['Punda Vibes E-Bike experience', 'City e-Bike Tour', 'Shopping Tour', 'Culinary Tour']
      };

      const categorizedItems = Object.keys(categories).reduce((acc, category) => {
        acc[category] = items.filter(item => categories[category].includes(item.title));
        return acc;
      }, {});

      setMenuCategories(categorizedItems);
    };

    fetchMenuItems();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const handleCategoryClick = (category) => {
    setOpenCategories(prev => ({ ...prev, [category]: !prev[category] }));
  };

  return (
    <>
      <AppBar position="fixed" color="default" sx={{ background: 'transparent', boxShadow: 'none', zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar disableGutters sx={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingTop: theme.spacing(1) }}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{
              color: theme.palette.primary.main,
              marginLeft: 'auto',
              padding: theme.spacing(1),
              marginRight: theme.spacing(2),
              marginTop: theme.spacing(1),
              '& .MuiSvgIcon-root': { fontSize: '2.5rem' },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250, paddingTop: '64px' }}
          role="presentation"
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <img src="/images/Curacao_Tours_logo.png" alt="Curacao Tours Logo" style={{ maxWidth: '150px', height: 'auto' }} />
          </Box>
          <List>
            {Object.entries(menuCategories).map(([category, items]) => (
              <React.Fragment key={category}>
                <ListItem button onClick={() => handleCategoryClick(category)}>
                  <ListItemText primary={category} primaryTypographyProps={{ style: { fontWeight: 'bold', color: theme.palette.primary.dark } }} />
                  {openCategories[category] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openCategories[category]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {items.map((item) => (
                      <ListItem button key={item.title} component={Link} to={item.link} sx={{ pl: 4 }}>
                        <ListItemText primary={item.title} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
            <ListItem button component={Link} to="/local-deals">
              <ListItemText primary="LOCAL DEALS" />
            </ListItem>
            <ListItem button component={Link} to="/transfers">
              <ListItemText primary="TRANSFERS" />
            </ListItem>
            <ListItem button component={Link} to="/contact">
              <ListItemText primary="CONTACT" />
            </ListItem>
          </List>
          <Box sx={{ padding: theme.spacing(2) }}>
            <WeatherWidget />
          </Box>
          <Box sx={{ padding: theme.spacing(2), backgroundColor: theme.palette.secondary.main, textAlign: 'center' }}>
            <Typography variant="body2" sx={{ color: theme.palette.secondary.contrastText, fontWeight: 'bold' }}>
              Groups:
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.secondary.contrastText }}>
              For group inquiries, please contact Groups@Fb-tt.com.
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.secondary.contrastText, fontSize: '0.8rem', marginTop: '5px' }}>
              Plan the perfect group tour with Curaçao Tours! Contact us at groups@fb-tt.com or call 8699559 for exclusive group packages and custom itineraries. Book now for a unique experience!
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default MobileMenu;