// src/components/FooterMobile.js
import React from 'react';
import { AppBar, Toolbar, Button, Typography } from '@mui/material';

function FooterMobile({ onBookNowClick, title }) {
  return (
    <AppBar position="static" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title || "Curacao Tours"}
        </Typography>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={onBookNowClick}
          sx={{ marginLeft: 'auto' }}
        >
          Book Now
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default FooterMobile;
