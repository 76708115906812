import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import './dashboard.css'; // Make sure this path is correct

const CurrentMonthSardonyxPax = () => {
    const [currentMonthPax, setCurrentMonthPax] = useState(0); // State to store current month Pax
    const [ytdAmount, setYtdAmount] = useState(0);
    const [previousYearPax, setPreviousYearPax] = useState(0); // State to store previous year Pax
    const [previousYearYtdAmount, setPreviousYearYtdAmount] = useState(0); // State to store previous year YTD amount
    const [previousYearMonthPax, setPreviousYearMonthPax] = useState(0); // State to store previous year current month Pax

    const fetchPaxData = async () => {
        const currentRange = `Sardonyx!A2:C`;
        const previousRange = `Sardonyx2023!A2:C`;
        const currentFunctionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(currentRange)}`;
        const previousFunctionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(previousRange)}`;
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const previousYear = currentYear - 1;

        try {
            const [currentResponse, previousResponse] = await Promise.all([
                fetch(currentFunctionUrl),
                fetch(previousFunctionUrl)
            ]);

            if (!currentResponse.ok || !previousResponse.ok) throw new Error('Failed to fetch');

            const currentData = await currentResponse.json();
            const previousData = await previousResponse.json();

            let yearToDateAmount = 0;
            let monthPax = 0; // Variable to store current month Pax
            let prevYearPax = 0; // Variable to store previous year Pax
            let prevYearYtdAmount = 0; // Variable to store previous year YTD amount
            let prevYearMonthPax = 0; // Variable to store previous year current month Pax

            currentData.forEach(row => {
                const date = new Date(row[0]);
                const amount = parseFloat(row[2]); // Parse amount from the third column
                const pax = parseInt(row[2], 10); // Parse Pax from the third column
                if (!isNaN(amount)) {
                    if (date.getFullYear() === currentYear) {
                        yearToDateAmount += amount;
                        if (date.getMonth() === currentMonth) {
                            monthPax += pax; // Add Pax if it's in the current month
                        }
                    }
                }
            });

            previousData.forEach(row => {
                const date = new Date(row[0]);
                const amount = parseFloat(row[2]); // Parse amount from the third column
                const pax = parseInt(row[2], 10); // Parse Pax from the third column
                if (!isNaN(amount)) {
                    if (date.getFullYear() === previousYear && date <= currentDate) {
                        prevYearYtdAmount += amount;
                        if (date.getMonth() === currentMonth) {
                            prevYearMonthPax += pax; // Add Pax if it's in the same month of the previous year
                        }
                    }
                }
            });

            setYtdAmount(yearToDateAmount);
            setCurrentMonthPax(monthPax); // Update state with current month Pax
            setPreviousYearPax(prevYearPax); // Update state with previous year Pax
            setPreviousYearYtdAmount(prevYearYtdAmount); // Update state with previous year YTD amount
            setPreviousYearMonthPax(prevYearMonthPax); // Update state with previous year current month Pax
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchPaxData();
    }, []);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentMonthName = monthNames[new Date().getMonth()];

    return (
        <Card className="sardonyx-card">
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Sardonyx Pax for {currentMonthName}
                </Typography>
                <Typography variant="h5">
                    {currentMonthName} Pax: {currentMonthPax}
                </Typography>
                <Typography variant="h5" style={{ marginTop: '8px' }}>
                    YTD Total: {ytdAmount}
                </Typography>
                <Typography variant="h5" style={{ marginTop: '8px' }}>
                    Previous Year {currentMonthName} Pax: {previousYearMonthPax}
                </Typography>

            </CardContent>
        </Card>
    );
};

export default CurrentMonthSardonyxPax;