import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './dashboard.css'; // Ensure this path is correct

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const NumberOfPassengersSardonyx = () => {
    const [chartData, setChartData] = useState({
        labels: Array.from({ length: 52 }, (_, i) => `Week ${i + 1}`),
        datasets: []
    });

    const fetchData = async () => {
        const years = [2023, 2024]; // Fetch data for both 2023 and 2024
        let datasets = [];
        let totalPassengers = new Array(52).fill(0);
        let nonServicePassengers = new Array(52).fill(0);

        const colors = [
            { backgroundColor: 'rgba(255, 99, 132, 0.6)', borderColor: 'rgba(255, 99, 132, 1)' },
            { backgroundColor: 'rgba(54, 162, 235, 0.6)', borderColor: 'rgba(54, 162, 235, 1)' }
        ];

        for (const [index, year] of years.entries()) {
            const sheetName = year === 2024 ? 'Sardonyx' : `Sardonyx${year}`; // Determine the correct sheet name based on the year
            const range = `${sheetName}!A2:G`;
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=${encodeURIComponent(range)}`;

            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                let weeklyPassengerMap = new Array(52).fill(0);
                data.forEach(row => {
                    if (!row || !row[0]) return; // Check if row or date is undefined
                    const dateStr = row[0];
                    let date;
                    if (year === 2024) { // DD/MM/YYYY format
                        const [day, month, year] = dateStr.split('/').map(Number);
                        date = new Date(year, month - 1, day);
                    } else { // MM/DD/YYYY format
                        const [month, day, year] = dateStr.split('/').map(Number);
                        date = new Date(year, month - 1, day);
                    }

                    if (date.getFullYear() === year) {
                        const week = Math.ceil((date - new Date(year, 0, 1)) / (7 * 24 * 60 * 60 * 1000));
                        const passengers = parseInt(row[2], 10);
                        weeklyPassengerMap[week - 1] += passengers;
                        totalPassengers[week - 1] += passengers;
                        if (passengers === 0) {
                            nonServicePassengers[week - 1] += 1;
                        }
                    }
                });

                datasets.push({
                    label: `Passengers ${year}`,
                    data: weeklyPassengerMap,
                    backgroundColor: colors[index].backgroundColor,
                    borderColor: colors[index].borderColor,
                    borderWidth: 1,
                    yAxisID: 'y'
                });
            } catch (error) {
                console.error(`Error fetching data for year ${year}:`, error);
            }
        }

        // Calculate percentage of non-service passengers
        const percentageNonService = totalPassengers.map((total, index) => {
            return total === 0 ? 0 : (nonServicePassengers[index] / total) * 100;
        });

        datasets.push({
            label: 'Percentage of PAX not using services',
            data: percentageNonService,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            type: 'line', // Display as a line chart
            yAxisID: 'y1'
        });

        setChartData({
            labels: chartData.labels,
            datasets
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const refreshData = () => {
        fetchData();
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.parsed.y}`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#333',
                },
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                    color: '#eaeaea',
                },
                ticks: {
                    color: '#333',
                    beginAtZero: true,
                },
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                ticks: {
                    color: '#333',
                    beginAtZero: true,
                },
            },
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
        },
    };

    return (
        <div className="chart-container">
            <h2 className="chart-title">Number of Passengers with Transfers (Sardonyx)</h2>
            <button onClick={refreshData}>Refresh Data</button>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default NumberOfPassengersSardonyx;
