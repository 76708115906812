import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { Global, css } from '@emotion/react';

const VideoBanner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    if (videoRef.current && location.pathname === '/') {
      videoRef.current.play().catch(error => console.error('Error playing video:', error));
    }
  }, [location.pathname]);

  const handleLoadedData = () => {
    setVideoLoaded(true);
  };

  const handleBookNowClick = () => {
    navigate('/');
    setTimeout(() => {
      const section = document.getElementById('target-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  if (location.pathname !== '/') {
    return null;
  }

  return (
    <>
      <Global
        styles={css`
          video::-webkit-media-controls, video::-moz-media-controls {
            display: none !important;
          }
          video {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            scrollbar-width: none; /* For Firefox */
            display: block; /* Ensuring video is always block to maintain layout */
            width: 100%;
            height: 800px; /* Explicit height to match the container */
            object-fit: cover;
          }
          video::-webkit-scrollbar {
            display: none !important; /* For Chrome, Safari, and Opera */
          }
          @media (max-width: 600px) {
            video {
              height: 400px; /* Reduced height for mobile */
            }
          }
        `}
      />
      <Box sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '400px', md: '800px' }, // Responsive height
        overflow: 'hidden'
      }}>
        {!videoLoaded && (
          <img
            src="/images/VideoCover.png"
            alt="Loading video"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        )}
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={handleLoadedData}
          controls={false}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        >
          <source src="https://firebasestorage.googleapis.com/v0/b/curacao-tours.appspot.com/o/CuracaoToursSmallVideo.mp4?alt=media&token=3a05e149-8fc5-47f9-83c6-51cbf40dfe72" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Box sx={{
          position: 'absolute',
          top: { xs: '50%', md: '60%' },
          left: { xs: '5%', md: '10%' },
          transform: 'translateY(-50%)',
          color: 'white',
          textAlign: 'left',
          zIndex: 3,
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          padding: { xs: '10px', md: '20px' },
          borderRadius: '8px',
          width: { xs: '90%', md: 'auto' }
        }}>
          <Typography variant="h3" component="h2" sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', md: '3.5rem' }
          }}>
            Live the Dream:<br />
            Unleash Unforgettable<br />
            Island Experiences!
          </Typography>
          <Button
            variant="contained"
            sx={{
              mt: { xs: 2, md: 5 },
              backgroundColor: '#1A3D8F',
              color: 'white',
              ':hover': { backgroundColor: '#16357A' },
              fontSize: { xs: '1rem', md: '1.25rem' },
              padding: { xs: '8px 16px', md: '10px 20px' }
            }}
            onClick={handleBookNowClick}
          >
            Book Now
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default VideoBanner;
