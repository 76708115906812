import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MonthlyRevenueByAgent = () => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });

    useEffect(() => {
        const fetchData = async (year) => {
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_${year}!A2:G`;
            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                let agentRevenueMap = {};

                data.forEach(row => {
                    const date = new Date(row[0]);
                    const month = date.getMonth();
                    const agentName = row[2];
                    const revenue = parseFloat(row[4]) || 0;

                    if (!agentRevenueMap[agentName]) {
                        agentRevenueMap[agentName] = new Array(12).fill(0);
                    }
                    agentRevenueMap[agentName][month] += revenue;
                });

                const datasets = Object.keys(agentRevenueMap).map(agentName => ({
                    label: agentName,
                    data: agentRevenueMap[agentName],
                    backgroundColor: getRandomColor(),
                }));

                setChartData(prevData => ({
                    ...prevData,
                    datasets,
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(selectedYear);
    }, [selectedYear]);

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    return (
        <div>
            <h2>Monthly Revenue by Agent</h2>
            <div>
                <label htmlFor="year-select">Select Year:</label>
                <select id="year-select" value={selectedYear} onChange={handleYearChange}>
                    {/* Example years; adjust according to your data */}
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                </select>
            </div>
            <Bar data={chartData} options={{ responsive: true }} />
        </div>
    );
};

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export default MonthlyRevenueByAgent;
