import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MonthlyRevenueByCompany = () => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });

    useEffect(() => {
        const fetchData = async (year) => {
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_${year}!A2:G`;
            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                // Organize data by company, summing revenue for each month
                let companyRevenueMap = {};
                data.forEach(row => {
                    const date = new Date(row[0]);
                    const month = date.getMonth();
                    const company = row[1]; // Assuming company name is in the second column
                    const revenue = parseFloat(row[4]) || 0;

                    if (!companyRevenueMap[company]) {
                        companyRevenueMap[company] = new Array(12).fill(0);
                    }
                    companyRevenueMap[company][month] += revenue;
                });

                // Convert the map into datasets for the chart
                const datasets = Object.keys(companyRevenueMap).map((company, index) => ({
                    label: `${company} (${year})`,
                    data: companyRevenueMap[company],
                    backgroundColor: getRandomColor(index),
                }));

                setChartData({
                    labels: chartData.labels,
                    datasets: datasets,
                });
            } catch (error) {
                console.error(`Error fetching data for ${year}:`, error);
            }
        };

        fetchData(selectedYear);
    }, [selectedYear]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
                beginAtZero: true,
            },
        },
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    return (
        <div>
            <h2>Monthly Revenue by Company</h2>
            <div>
                <label htmlFor="year-select-company">Select Year:</label>
                <select id="year-select-company" value={selectedYear} onChange={handleYearChange}>
                    {[2021, 2022, 2023, 2024].map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>
            <Bar data={chartData} options={options} />
        </div>
    );
};

// Utility function to generate distinct colors for each company
const getRandomColor = (index) => {
    const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
    return colors[index % colors.length];
};

export default MonthlyRevenueByCompany;
